import React from "react"
import SEO from '../components/seo'
import ogImage from "../images/og-image.png"
import "../styles/styles.scss"

const PrivacyPolicyPage = () => (
  <>
    <SEO title="Privacy Policy" image={ogImage} />
    <div className="privacy_container">
      <strong>Privacy Policy</strong>
      <p>
        This privacy policy applies to the MusixMize - Media Player app for Android devices that was created by Texultant Technologies (hereby referred
        to as "We") as a Free service and is intended for use as is.
      </p>
      <p><strong>Information Collection and Use</strong></p>
      <p>
        For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The
        information that We request will be retained on your device and is not collected by us in any way.
      </p>
      <div>
        <p>The app does use third party services that may collect information used to identify you.</p>
        <p>
          Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to
          the Privacy Policy of the third-party service providers used by the app:
        </p>
        <ul>
          <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        </ul>
      </div>
      <p><strong>Log Data</strong></p>
      <p>
        We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other
        statistics.
      </p>
      <p><strong>Cookies</strong></p>
      <p>
        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
      </p>
      <p><strong>Service Providers</strong></p>
      <p>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
      </p>
      <p><strong>Security</strong></p>
      <p>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>
      <p><strong>Children's Privacy</strong></p>
      <p>
        These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13
        years of age. In the case We discover that a child under 13 has provided us with personal information, We immediately delete this from our
        servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that
        we will be able to do necessary actions.
      </p>
      <p><strong>Changes</strong></p>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>This privacy policy is effective as of 2024-03-20</p>
      <p><strong>Your Consent</strong></p>
      <p>
        By using the Service, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
      </p>
      <p><strong>Contact Us</strong></p>
      <p>
        If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact us via email at
        support@musixmize.com.
      </p>
      <hr />
      <p>
        This privacy policy page was generated by
        <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer"> App Privacy Policy Generator </a>
      </p>
    </div>
  </>
)

export default PrivacyPolicyPage
